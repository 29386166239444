@import "../../resources/styles/colors";

.currentBranch {
    color: @secondaryText;
    font-size: 0.875em;
    display: flex;
    align-items: center;
}

.currentBranchValue {
    font-weight: bold;
}

.icon-container {
    margin-right: 2px;
    margin-left: 5px;
    display: flex;
    align-items: center;
}
