@import "../../resources/styles/media";
@import "../../resources/styles/colors";
@import "../../resources/styles/variables";

.transparentFrame {
    display: flex;
    flex-direction: row;

    width: 100%;
    max-width: 1000px;
    max-height: 100%;
    background: transparent;
    border-radius: 4px;
}

.contentPanel {
    display: flex;
    flex-direction: row;
    position: relative;

    width: 100%;
    height: 100%;

    max-width: 600px;
    min-height: 450px;

    background: @paper1;
    border-radius: 4px;
    box-shadow: @boxshadow4;
    margin: auto;

    transition: transform 1s;
    transform: translateX(0);

    z-index: 10;
}

.contentPanelWithHelpPanel {
    @media (min-width: @screen-sm) {
        border-radius: 4px 0 0 4px;
    }
}

.helpPanel {
    flex: auto;
    position: relative;
    overflow: hidden;

    background: @userOnboardingHelpPanelBackground;
    border-radius: 0 4px 4px 0;
    box-shadow: @boxshadow4;

    max-width: 400px;
    height: 100%;
    align-content: center;

    @media (max-width: @screen-sm) {
        display: none;
    }
}

.helpPanel:not(.active):not(.animate) {
    display: none;
}

.helpPanel.animate {
    animation: slide-out 0.35s forwards;
}

.helpPanel.animate.active {
    animation: slide-in 0.35s forwards;
}

@keyframes slide-in {
    0% {
        margin-left: -40%;
        transform: translateX(-60%);
        box-shadow: none;
    }
    100% {
        margin-left: 0;
        transform: translateX(0%);
        width: 100%;
        box-shadow: @boxshadow4;
    }
}

@keyframes slide-out {
    0% {
        margin-left: 0;
        transform: translateX(0%);
        box-shadow: @boxshadow4;
    }
    100% {
        margin-left: -40%;
        transform: translateX(-60%);
        box-shadow: none;
    }
}

.helpPanelBtn {
    position: absolute;
    right: 20px;
    top: 20px;
    z-index: 10;
    @media (max-width: @screen-sm) {
        visibility: hidden;
    }
}

.helpPanelText {
    display: block;
    width: 80%;
    margin: 30px auto 0;
    color: white;
    line-height: 24px;
    font-weight: 400;
    font-size: 16px;
}

.helpPanelImage {
    display: block;
    width: 80%;
    margin: 80px auto 20px;
}

.padded {
    padding: 5px 10px 10px;
}

.dashboardOnboardingLayout {
    display: flex;
    flex-direction: row;

    max-width: 80%;
    margin-top: 3rem;
    @media (max-width: @screen-md) {
        margin-top: 0.25rem;
        flex-direction: column-reverse;
    }
}

.dashboardOnboardingText {
    line-height: 24px;
    max-width: 30rem;
    color: @ternaryText;
    padding: 10rem 3rem 10rem 0;
    @media (max-width: @screen-md) {
        max-width: 400px;
        padding: 0.5rem 0;
    }
}

.dashboardOnboardingImage {
    max-width: 530px;
    min-width: 0;
    @media (max-width: @screen-md) {
        max-width: 400px;
        padding: 0.5rem 0;
    }
}

.openDialogButton {
    padding-top: 2rem;
}

.checkboxGrid {
    display: flex;
    flex-flow: row wrap;

    > * {
        flex-grow: 1;
        flex-basis: 100%;
        margin: 5px 5px 5px 0;
        border-radius: 5px;
        border-width: 2px;
        border-style: solid;
        border-color: @disabledButtonBorder;

        @media (min-width: @screen-sm) {
            flex-grow: 0;
            flex-basis: 32%;
            min-width: 9rem;
        }
    }
}

.checkboxGridItem {
    padding: 4px;
}

.lifecycleSummary {
    margin-top: 2rem;
}

.lifecycleList {
    list-style-type: decimal;
    list-style-position: inside;
    font-weight: bold;
}

.lifecycleListItem {
    float: left;
    padding-right: 1rem;
}

.successfulDeploymentDialog {
    display: flex;
    flex-direction: row;

    max-width: 1000px;
    max-height: 100%;
    background: transparent;
    box-shadow: @boxshadow4;
}

.successfulDeploymentDialogContent {
    display: flex;
    flex-direction: column;

    width: 100%;
    max-width: 450px;
    max-height: 100%;

    background: @paper1;
    padding: 1rem;
    border-radius: 4px 0 0 4px;

    @media (max-width: @screen-sm) {
        border-radius: 4px;
    }
}

.successfulDeploymentDialogImage {
    height: 100%;
    border-radius: 0 4px 4px 0;
    @media (max-width: @screen-sm) {
        display: none;
    }
}

.successfulDeploymentDialogTitle {
    flex-grow: 1;
    font-size: 20px;
    font-weight: 500;
    padding-left: 1rem;
    padding-right: 1rem;
}

.successfulDeploymentDialogText {
    font-size: 16px;
    line-height: 24px;
}

.externalLinkIcon {
    margin-right: 1rem;
    font-size: 0.8125rem;
    color: @linkText;
}

.blankPageOuterContainer {
    margin: 2rem auto;
    padding: 1rem;
    display: flex;
    flex-direction: row;
    width: 60%;
    @media (max-width: @screen-md) {
        margin: auto;
        padding: 0;
        width: 90%;
        flex-direction: column-reverse;
    }
}

.blankPageContentContainer {
    display: flex;
    flex-direction: column;
    color: @ternaryText;
    font-size: 0.875rem;
    width: 50%;
    max-width: 450px;
    @media (max-width: @screen-md) {
        margin: auto;
        width: 100%;
        max-width: 400px;
        padding: 1rem 0 3rem 0;
    }
}

.header {
    color: @primaryText;
    font-weight: @semibold;
    line-height: 26px;
}

.description {
    color: @ternaryText;
    margin-bottom: 1rem;
}

.externalLinks {
    color: @ternaryText;
    display: flex;
    align-items: center;
    >a, >div {
        margin: 0 2rem 1rem 0;
    }
}

.blankPageImage {
    float: right;
    margin: 1rem 0 0 1rem;
    max-width: 400px;
    @media (max-width: @screen-md) {
        margin: auto;
        width: 100%;
        float: unset;
        padding-top: 0.5rem;
    }
}

.noFrameFlex {
    display: flex;
    flex-direction: column;
}

.continueOnboardingPanel {
    background: @paper1;
    box-shadow: @boxshadow2;
    padding: 1rem;
    border-radius: 5px;
    min-width: 18rem;

    >div {
        margin: 0 0 1rem 0;
    }
}

.linkList {
    font-size: 0.85rem
}

.icon {
    margin-right: 0.15em;
}